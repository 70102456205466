import "./App.css";
import { useLayoutEffect, useState } from "react";
import Home from "./pages/Home";
import BdayAdvent from "./pages/BdayAdvent";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function App() {
  const [width, height] = useWindowSize();
  if (window.location.pathname === "/2022") {
    return <BdayAdvent width={width} height={height} />;
  }
  return <Home width={width} height={height} />;
}

export default App;
