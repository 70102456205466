import Popup from "react-popup";
import "../popup.css";

/*
cruise
pumpkin carving
haunted house
sf zoo (weekend)
muni trip
Let's take a big bus tour of SF!
*/

const BDAY = new Date("2022-11-04");
const EVENTS = [
  {
    date: "2022-10-04",
    event: "We're making ice cream cookie sandwiches!!",
    emoji: "🍦",
  },
  { date: "2022-10-05", event: "Rom com movie night!", emoji: "🎥" },
  { date: "2022-10-06", event: "Let's make homemade pasta!", emoji: "🍝" },
  { date: "2022-10-07", event: "Have some sunflowers!", emoji: "🌼" },
  { date: "2022-10-08", event: "Comedy show!!", emoji: "😂" },
  {
    date: "2022-10-09",
    event: "Learn to code with an expert (:",
    emoji: "👨‍💻",
  },
  {
    date: "2022-10-10",
    event: "Reading night! You got a cool book",
    emoji: "📙",
  },
  { date: "2022-10-11", event: "Boba day!", emoji: "🍹" },
  {
    date: "2022-10-12",
    event: "Lego day! You got a cool lego set",
    emoji: "🧱",
  },
  { date: "2022-10-13", event: "We're making affogatos!!", emoji: "🍦" },
  {
    date: "2022-10-14",
    event: "Let's watch your favorite childhood TV shows!",
    emoji: "📼",
  },
  { date: "2022-10-15", event: "Let's go on a sunset picnic!!", emoji: "🌇" },
  { date: "2022-10-16", event: "We're going hiking!!", emoji: "🚶" },
  { date: "2022-10-17", event: "Let's make homemade sushi!", emoji: "🍣" },
  { date: "2022-10-18", event: "Let's learn to play the piano!", emoji: "🎹" },
  {
    date: "2022-10-19",
    event: "Have a giftcard for some cool SF classes!",
    emoji: "🏫",
  },
  {
    date: "2022-10-20",
    event:
      "Let's celebrate this Thursday with some charcuterie and (sweet) wine!",
    emoji: "🍷",
  },
  {
    date: "2022-10-21",
    event: "We're going to a fancy place for dinner",
    emoji: "🍽️",
  },
  {
    date: "2022-10-22",
    event: "Let's start our day at a local tea shop!",
    emoji: "🍵",
  },
  {
    date: "2022-10-23",
    event: "Let's go get some pumpkins!",
    emoji: "🎃",
  },
  { date: "2022-10-24", event: "Let's explore a local bookshop!", emoji: "📚" },
  {
    date: "2022-10-25",
    event: "Today we're finally setting up Kaci's nightstand!",
    emoji: "⚒️",
  },
  { date: "2022-10-26", event: "Break day", emoji: "😴" },
  { date: "2022-10-27", event: "Break day", emoji: "😴" },
  { date: "2022-10-28", event: "Break day", emoji: "😴" },
  {
    date: "2022-10-29",
    event: "Break day",
    emoji: "😴",
  },
  { date: "2022-10-30", event: "Break day", emoji: "😴" },
  { date: "2022-10-31", event: "Break day", emoji: "😴" },
  { date: "2022-11-01", event: "Break day", emoji: "😴" },
  { date: "2022-11-02", event: "Break day", emoji: "😴" },
  { date: "2022-11-03", event: "Break day", emoji: "😴" },
  { date: "2022-11-04", event: "Happy bday! Cake time!!!", emoji: "🎂" },
];

function Box({ event, index, today }) {
  let color = 200 - 20 * (index % 7);
  let eventDate = new Date(event.date);
  let eventDateFormatted = `${eventDate.getMonth() + 1} / ${
    eventDate.getDate() + 1
  }`;
  let locked = !(eventDate <= today);
  return (
    <div
      style={{
        display: "inline-block",
        backgroundColor: `rgb(${color}, ${color}, ${color})`,
        width: "100px",
        height: "100px",
        marginLeft: "10px",
        marginBottom: "10px",
        cursor: "pointer",
        textAlign: "center",
      }}
      onClick={() => {
        if (!locked) {
          Popup.create({
            title: `${eventDateFormatted} ${event.emoji}`,
            content: event.event,
          });
        } else {
          Popup.create({
            title: "Locked!",
            content: `You'll have to wait before you can see what's planned on this day!`,
          });
        }
      }}
    >
      <div
        style={{
          padding: "8px",
          color: `rgb(${130 - color}, ${130 - color}, ${130 - color})`,
        }}
      >
        <b>{eventDateFormatted}</b>
      </div>
      <div style={{ paddingTop: "10px" }}>{!locked ? event.emoji : "🔒"}</div>
    </div>
  );
}

function BdayAdvent({ width, height }) {
  let today = new Date(Date.now() - 8 * 60 * 60 * 1000);
  let daysBefore = Math.max(
    0,
    Math.floor(((BDAY - today) / (1000 * 60 * 60 * 24)) * 100) / 100
  );
  let boxesPerRow = Math.min(width / 150, 7);
  return (
    <div
      className="App"
      style={{
        display: "block",
        width: width + "px",
        height: height + "px",
        paddingTop: "3rem",
        paddingLeft: "4rem",
        paddingBottom: "2rem",
        paddingRight: "4rem",
      }}
    >
      <div style={{ fontSize: "2em", fontWeight: "600" }}>
        Kaci Bday 2022 🎂
      </div>
      <div
        style={{
          fontSize: "2.4em",
          paddingTop: "40px",
          fontWeight: "700",
          color: "#3a3a3b",
          width: `${width - 100}px`,
        }}
      >
        T-{daysBefore} days before her birthday!
      </div>
      <div style={{ paddingTop: "20px", width: `${width - 100}px` }}>
        {EVENTS.map((e, i) => [
          <Box event={e} index={i} today={today} />,
          i % boxesPerRow === boxesPerRow - 1 && <br />,
        ])}
      </div>
      <Popup style={{ width: `${width}px` }} />
    </div>
  );
}

export default BdayAdvent;
