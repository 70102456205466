function Home({ width, height }) {
  return (
    <div
      className="App"
      style={{
        display: "block",
        width: width + "px",
        height: height + "px",
        paddingTop: "3rem",
        paddingLeft: "4rem",
        paddingBottom: "1rem",
        paddingRight: "4rem",
      }}
    >
      <div
        style={{
          fontSize: "2em",
          fontWeight: "600",
        }}
      >
        <a
          style={{ textDecoration: "none", color: "#1a1b1f" }}
          href="https://kacinguyen.com/"
        >
          Kaci Nguyen ❤️
        </a>
      </div>
      <div
        style={{
          fontSize: "2.4em",
          paddingTop: "90px",
          fontWeight: "700",
          color: "#3a3a3b",
          width: `${width - 100}px`,
        }}
      >
        Welcome, this is the Kaci is cool fan page. She's an amazing creator,
        learner, and girlfriend.
      </div>
      <div
        style={{
          paddingTop: "70px",
          fontWeight: "600",
          fontSize: "1.4em",
          color: "#6e6e6e",
        }}
      >
        CURRENT
      </div>
      <div style={{ paddingTop: "10px" }}>
        An RPM at Intuit, a great friend, &amp; an amazing GF
      </div>
      <div
        style={{
          paddingTop: "40px",
          fontWeight: "600",
          fontSize: "1.4em",
          color: "#6e6e6e",
        }}
      >
        SOON
      </div>
      <div style={{ paddingTop: "10px" }}>
        She'll be <a href="/2022">23</a>
      </div>
    </div>
  );
}

export default Home;
